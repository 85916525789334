export function deepClone(obj, hash = new WeakMap()) {
  if (Object(obj) !== obj) return obj // Primitive value
  if (hash.has(obj)) return hash.get(obj) // Circular reference
  let copy
  if (Array.isArray(obj)) {
    // Handle arrays
    copy = obj.map((item) => deepClone(item, hash))
  } else if (typeof obj === 'object' && obj !== null) {
    // Handle objects
    copy = Object.create(Object.getPrototypeOf(obj))
    // Add this object to the hash map
    hash.set(obj, copy)
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        copy[key] = deepClone(obj[key], hash)
      }
    }
  } else {
    // Copy primitive values
    copy = obj
  }
  return copy
}
